import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import loginMap from "../assets/images/logo.png";
import "../admin-login/Login.css";
import style from "./Login.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("info") != null) {
      console.log("User is already logged in");
      navigate("/");
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginData = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);
      const response = await axios.post(
        API_BASE_URL + "admin/login",
        formData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      resetForm();
      if (response.data.status) {
        sessionStorage.setItem("info", JSON.stringify(response.data.data));
        navigate("/");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Admin login | PGI Computers</title>
      </Helmet>
      <section className="loginMainSection">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="main-section ps-0 pe-0 d-flex">
              <div className="col-lg-8 col-md-12 d-flex justify-content-center align-items-center">
                <div className={`${style.map_sec}`}>
                  <h1 className={`${style.login_left_text}`}>PGI Computers ADMIN</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 login_right_sec">
                <div className="login-sec">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={loginData}
                  >
                    {({ touched, errors }) => (
                      <Form className={`${style.form}`}>
                        {/* <div className="login_right_logo_">
                          <img
                            src={loginMap}
                            alt="login"
                            className={`${style.login_logo} img-fluid`}
                            style={{ height: "50px", width: "142px" }}
                          />
                        </div> */}
                        <h1 className="login-text text-center mb-4">Login</h1>
                        <div className={`${style.password_sec}`}>
                          <Field
                            type="text"
                            name="email"
                            placeholder="Email"
                            className={`${style.username} ${style.input} ${
                              touched.email && errors.email
                                ? `${style.invalid}`
                                : ""
                            }`}
                            autoComplete="off"
                          />
                          <ErrorMessage
                            className={`text-danger ${style.error_text}  text-start`}
                            name="email"
                            component="div"
                          />
                        </div>

                        <div className={`${style.password_sec}`}>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            autoComplete="off"
                            className={`${style.password} ${style.input} ${
                              touched.password && errors.password
                                ? `${style.invalid}`
                                : ""
                            }`}
                          />
                          <button
                            type="button"
                            className={`${style.password_toggle_button}`}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <FaEye color="#a1a1a1" />
                            ) : (
                              <FaEyeSlash color="#a1a1a1" />
                            )}
                          </button>
                          <ErrorMessage
                            className={`text-danger ${style.error_text}  text-start`}
                            name="password"
                            component="div"
                          />
                        </div>

                        <div>
                          <button className={`${style.btn}`} type="submit">
                            Login
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="develope_bottom_text">
                    <p>
                      Design & Developed By{" "}
                      <Link
                        to="https://www.microdynamicsoftware.com/"
                        target="_blank"
                      >
                        Microdynamic Software Pvt.Ltd
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </React.Fragment>
  );
};

export default AdminLogin;
