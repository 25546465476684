import { HelmetProvider } from "react-helmet-async";
import Dashboard from "./component/Dashboard/Dashboard";
import MyBeShowNavbar from "./component/MyBeShowNavbar/MyBeShowNavbar";
import React from "react";
import { MyContextProvider } from "./context/MainContext";

function App() {
  return (
    <MyContextProvider>
      <HelmetProvider>
        <MyBeShowNavbar>
          <Dashboard />
        </MyBeShowNavbar>
      </HelmetProvider>
    </MyContextProvider>
  );
}

export default App;
