import { createContext, useContext, useState } from "react";
const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [themeLogo, setThemeLogo] = useState("");

  return (
    <MyContext.Provider value={{themeLogo, setThemeLogo}}>
      {children}
    </MyContext.Provider>
  );
};
export const useMainContext = () => {
  return useContext(MyContext);
};

export default MyContext;
